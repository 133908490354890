import React, { useEffect } from 'react';
function Alumni() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className={'alumnipage'}>

            {/* ----- Alumni Banner ----- */}

            <section className="academics-banner">
                <img src="images/alumni/almuni.png" alt="Alumni_Banner" />
            </section>

            {/* ----- Alumni Banner ----- */}

            {/* ----- Overview ----- */}

            <section className="overview">
                <div className="container">
                    <div className="overview-box">
                        <h2 className="hdng-h2">Our Alumni, Our Pride</h2>
                        {/* <p className="para-18">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>
                </div>
            </section>

            {/* ----- Overview ----- */}

            {/* ----- Alumni ----- */}

            <section className="alumni-block">
                <div className="container">
                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/01-rainbow.png" alt="01-rainbow" />
                            <h4>Aakrshita</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/02-rainbow.png" alt="02-rainbow" />
                            <h4>Abhay Bharti</h4>
                            <p>AFMC, Pune</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/03-rainbow.png" alt="03-rainbow" />
                            <h4>Anuj</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/04-rainbow.png" alt="04-rainbow" />
                            <h4>Arnav</h4>
                            <p>JIIT, Noida</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/05-rainbow.png" alt="05-rainbow" />
                            <h4>Arushi</h4>
                            <p>RGMC, Hamirpur</p>
                        </div>
                    </div>

                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/06-rainbow.png" alt="06-rainbow" />
                            <h4>Aryan</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/07-rainbow.png" alt="07-rainbow" />
                            <h4>Ishita</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/08-rainbow.png" alt="08-rainbow" />
                            <h4>Nidish</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/09-rainbow.png" alt="09-rainbow" />
                            <h4>Sonal</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/10-rainbow.png" alt="10-rainbow" />
                            <h4>Trigun</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                    </div>

                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/11-rainbow.png" alt="11-rainbow" />
                            <h4>Vinayak</h4>
                            <p>NDA</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/12-rainbow.png" alt="12-rainbow" />
                            <h4>Aanchal Rana</h4>
                            <p>MBBS, Chamba</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/13-rainbow.png" alt="13-rainbow" />
                            <h4>Aditya Kumar</h4>
                            <p>IIT, Roorkee</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/14-rainbow.png" alt="14-rainbow" />
                            <h4>Akanksha</h4>
                            <p>BDS/MNS</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/15-rainbow.png" alt="15-rainbow" />
                            <h4>Alisha</h4>
                            <p>MBBS, Tanda</p>
                        </div>
                    </div>


                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/16-rainbow.png" alt="16-rainbow" />
                            <h4>Ananya</h4>
                            <p>B.V.SC. Palampur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/17-rainbow.png" alt="17-rainbow" />
                            <h4>Aniket</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/18-rainbow.png" alt="18-rainbow" />
                            <h4>Ankit Choudhary</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/19-rainbow.png" alt="19-rainbow" />
                            <h4>Ankita (MBBS)</h4>
                            <p>IGMC, Shimla</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/20-rainbow.png" alt="20-rainbow" />
                            <h4>Ankita (MBBS)</h4>
                            <p>IGMC, Shimla</p>
                        </div>
                    </div>


                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/21-rainbow.png" alt="21-rainbow" />
                            <h4>Anuj Kumar</h4>
                            <p>IIT, Roorkee</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/22-rainbow.png" alt="22-rainbow" />
                            <h4>Aryan</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/23-rainbow.png" alt="23-rainbow" />
                            <h4>Ayush</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/24-rainbow.png" alt="24-rainbow" />
                            <h4>Harsh</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/25-rainbow.png" alt="25-rainbow" />
                            <h4>Himani Anand</h4>
                            <p>MBBS, Chamba</p>
                        </div>
                    </div>

                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/26-rainbow.png" alt="26-rainbow" />
                            <h4>Hritik (MBBS)</h4>
                            <p>RPGMC, Tanda</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/27-rainbow.png" alt="27-rainbow" />
                            <h4>Kshitiz (MBBS)</h4>
                            <p>RPGMC, Tanda</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/28-rainbow.png" alt="28-rainbow" />
                            <h4>Lipakshi (MBBS)</h4>
                            <p>IGMC, Shimla</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/29-rainbow.png" alt="29-rainbow" />
                            <h4>Manan Makkar</h4>
                            <p>MBBS-MMU Chamba</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/30-rainbow.png" alt="30-rainbow" />
                            <h4>Modika</h4>
                            <p>MMU, Solan</p>
                        </div>
                    </div>

                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/31-rainbow.png" alt="31-rainbow" />
                            <h4>Mridul</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/32-rainbow.png" alt="32-rainbow" />
                            <h4>Nandita</h4>
                            <p>BDS</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/33-rainbow.png" alt="33-rainbow" />
                            <h4>Rishita</h4>
                            <p>MBBS, Chamba</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/34-rainbow.png" alt="34-rainbow" />
                            <h4>Sakshi</h4>
                            <p>MBBS, Nahan</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/35-rainbow.png" alt="35-rainbow" />
                            <h4>Sanskriti (MBBS)</h4>
                            <p>RGMC, Hamirpur</p>
                        </div>
                    </div>


                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/36-rainbow.png" alt="36-rainbow" />
                            <h4>Surbhi (MBBS)</h4>
                            <p>RPGMC, Tanda</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/37-rainbow.png" alt="37-rainbow" />
                            <h4>Tushar</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/38-rainbow.png" alt="38-rainbow" />
                            <h4>Vanshika</h4>
                            <p>PGI Chd. Paramedical</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/39-rainbow.png" alt="39-rainbow" />
                            <h4>Vanshika</h4>
                            <p>B.V.SC. Palampur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/40-rainbow.png" alt="40-rainbow" />
                            <h4>Vishal Dogra</h4>
                            <p>IIIT, Una</p>
                        </div>

                    </div>

                    <div className='alumni-block-box'>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/41-rainbow.png" alt="41-rainbow" />
                            <h4>Vishal Rana</h4>
                            <p>MBBS, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/42-rainbow.png" alt="42-rainbow" />
                            <h4>Vishav</h4>
                            <p>NIT, Hamirpur</p>
                        </div>
                        <div className='slider-pret'>
                            <img src="images/rainbow-alumni/43-rainbow.png" alt="43-rainbow" />
                            <h4>Yashika</h4>
                            <p>MBBS, Tanda</p>
                        </div>
                    </div>
                </div>

            </section >

            {/* ----- Alumni ----- */}

        </div >

    )
}


export default Alumni