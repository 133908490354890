import React, { useEffect } from 'react';

function TransferCertificates() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className={'transfer-certificate-page'}>


            {/* ----- Academics Banner ----- */}

            <section className="academics-banner">
                <img src="images/banner-images/academics-banner.jpg" alt="Academics_Banner" />
            </section>

            {/* ----- Academics Banner ----- */}

            {/* ----- Overview ----- */}

            <section className="overview">
                <div className="container">
                    <div className="overview-box">
                        <h2 className="hdng-h2">Transfer Certificates </h2>
                        {/* <p className="para-18">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1770s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                    </div>
                </div>
            </section>

            {/* ----- Overview ----- */}

            {/* ----- PDF Calender ----- */}

            <section className="pdf-calender">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-1.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-2.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-3.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-4.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-5.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-6.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-7.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-8.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-9.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-10.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>
                        <div className="col-md-6">
                            <embed src="pdf/new-tc/N-TC-11.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc1.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc2.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc3.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc4.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc5.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc6.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc7.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc8.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc9.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc10.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc11.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc12.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc13.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc14.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc15.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc16.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc17.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc18.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc19.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc20.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc21.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc22.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc23.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc24.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc25.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc26.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc27.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc28.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc29.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc30.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc31.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc32.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                        <div className="col-md-6">
                            <embed src="pdf/tc/tc33.pdf" type="application/pdf" width="100%" height="800px" />
                        </div>

                    </div>
                </div>
            </section>

            {/* ----- PDF Calender ----- */}

        </div>

    )
}


export default TransferCertificates