// GermanGateway.jsx

import React, { useEffect, useState } from 'react';

function Modal({ isOpen, onClose, imageUrl }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={onClose}>&times;</span>
                <img src={imageUrl} alt="modal" />
            </div>
        </div>
    );
}

function GermanGateway() {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={'german-gateway'}>
            {/* Academics Banner */}
            <section className="academics-banner">
                <img src="images/banner-images/academics-banner.jpg" alt="Academics_Banner" />
            </section>

            {/* Overview */}
            <section className="overview">
                <div className="container">
                    <div className="overview-box">
                        <p className="para-18 gd">At Rainbow International School, we're proud to offer German language classes through our German Language Department since 2015. Currently, over 600 students from grades 5 to 12 are learning German as a hobby, guided by two dedicated teachers. Our students are excelling in German proficiency and communication skills.</p>
                        <p className="para-18">We also provide international Goethe Fit A1, A2, B1, and B2 certificate courses, giving our students globally recognized qualifications. What sets us apart is that we're the only school in Himachal Pradesh offering German language education from A1 to B2 levels.</p>
                        <p className="para-18">Beyond language learning, our department opens doors to exciting career opportunities in foreign languages and higher education prospects in Germany. We're not just teaching a language; we're shaping futures and global perspectives.</p>
                    </div>
                </div>
            </section>

            {/* Image Gallery */}
            <div className="atal-tink-images">
                <div className="container">
                    <div className="atal-gallery">
                        <img src="images/German/german-1.jpg" alt="german1" onClick={() => openModal(`images/German/german-1.jpg`)}/>
                        <img src="images/German/german-2.jpg" alt="german2" onClick={() => openModal(`images/German/german-2.jpg`)}/>
                        <img src="images/German/german-3.jpg" alt="german3" onClick={() => openModal(`images/German/german-3.jpg`)}/>
                        <img src="images/German/german-4.jpg" alt="german4" onClick={() => openModal(`images/German/german-4.jpg`)}/>
                        <img src="images/German/german-5.jpg" alt="german5" onClick={() => openModal(`images/German/german-5.jpg`)}/>
                        <img src="images/German/german-6.jpg" alt="german6" onClick={() => openModal(`images/German/german-6.jpg`)}/>
                        <img src="images/German/german-7.jpg" alt="german7" onClick={() => openModal(`images/German/german-7.jpg`)}/>
                        <img src="images/German/german-8.jpg" alt="german8" onClick={() => openModal(`images/German/german-8.jpg`)}/>
                        <img src="images/German/german-9.jpg" alt="german9" onClick={() => openModal(`images/German/german-9.jpg`)}/>
                        <img src="images/German/german-10.jpg" alt="german10" onClick={() => openModal(`images/German/german-10.jpg`)}/>
                        <img src="images/German/german-11.jpg" alt="german11" onClick={() => openModal(`images/German/german-11.jpg`)}/>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal isOpen={modalOpen} onClose={closeModal} imageUrl={selectedImage} />
        </div>
    );
}

export default GermanGateway;